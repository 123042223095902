<template>
  <NavMenu/>
  <SectionHero/>
  <FloatingFooter/>
</template>

<script>
import NavMenu from './components/NavMenu.vue'
import SectionHero from './components/SectionHero.vue'
import FloatingFooter from './components/FloatingFooter.vue'

export default {
  components: {
    NavMenu, SectionHero, FloatingFooter
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Ubuntu&family=Ubuntu+Condensed&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  margin: 0;
  padding: 0;
}

.bold {
  font-weight: bold;
}

button {
  font-family: 'Ubuntu', sans-serif;
}

</style>
