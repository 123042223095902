<template>
  <ol>
    <li>Get the <a class="download-option" href="https://itch.io/app" target="_blank">Itch App</a></li>
    <li>Search <em>liberverse</em> in the Itch App</li>
    <li>Click the <strong>Install</strong> button</li>
  </ol>
</template>

<style>

</style>