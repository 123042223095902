<template>
  <nav id="menu">
    <a href="https://create.liberverse.net">Create</a>
    <a href="https://liberland.org">Liberland</a>
    <a href="https://status.liberverse.net">Service Status</a>
  </nav>
</template>

<script>
export default {
  props: {
    msg: String
  }
}
</script>

<style>
#menu {
  font-family: 'Ubuntu Condensed', sans-serif;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 50px;
  display: flex;
  justify-content: right;
  background-color: white;
}

@media screen and (max-width: 800px) {
  #menu { justify-content: center; }
}

#menu a, #menu a:visited {
  color: #333;
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

#menu a:hover, #menu a:active {
  color: #777;
}

</style>