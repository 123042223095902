<template>
  <section id="hero" :style="{ backgroundImage: `url(${require('@/assets/bg.jpg')})` }">
    <div id="hero-overlay">
      <div>
        <img src="@/assets/logo1000x200.png" alt="LIBERVERSE" />
        <p class="bold" style="margin-bottom: 60px;">Join the virtual version of Liberland, chat with others, discover planned projects, collaborate, and become part of the movement.</p>
        <div id="download-options">
          <div class="btn">
            <button 
              class="download"
              @click="windowsDownloadOptionsVisible = !windowsDownloadOptionsVisible" 
              :style="{ backgroundImage: `url(${require('@/assets/windows64.png')}), linear-gradient(180deg, rgba(255,147,0,1) 0%, rgba(255,0,183,1) 100%)` }">
                <span class="download-title">Download for Windows</span>
                <template v-if="windowsDownloadOptionsVisible">
                  <p class="requirements">Min. requirements: A dedicated graphics card with at least 3-4 GB of memory.</p>
                  <ul class="download-toggleable">
                    <li>
                      From Itch
                      <ItchInstructions></ItchInstructions>
                    </li>
                    <li>From <a class="download-option" href="https://www.microsoft.com/store/apps/9P3DS0TJ9R82" target="_blank">Microsoft Store</a></li>
                  </ul>
                </template>
              </button>
          </div>
          <div class="btn">
            <button 
              class="download"
              @click="macDownloadOptionsVisible = !macDownloadOptionsVisible" 
              :style="{ backgroundImage: `url(${require('@/assets/mac64.png')}), linear-gradient(180deg, rgba(255,147,0,1) 0%, rgba(255,0,183,1) 100%)` }">
                <span class="download-title">Download for MacOS</span>
                <template v-if="macDownloadOptionsVisible">
                  <p class="requirements">Min. requirements: Any Mac with a dedicated graphics card or Apple Silicon M1+.</p>
                  <ul class="download-toggleable">
                    <li>
                      From Itch
                      <ItchInstructions></ItchInstructions>
                    </li>
                  </ul>
                </template>
              </button>
          </div>
          <div class="btn">
            <button 
              class="download"
              @click="linuxDownloadOptionsVisible = !linuxDownloadOptionsVisible" 
              :style="{ backgroundImage: `url(${require('@/assets/linux64.png')}), linear-gradient(180deg, rgba(255,147,0,1) 0%, rgba(255,0,183,1) 100%)` }">
                <span class="download-title">Download for Linux</span>
                <template v-if="linuxDownloadOptionsVisible">
                  <p class="requirements">Min. requirements: A dedicated graphics card with at least 3-4 GB of memory.</p>
                  <ul class="download-toggleable">
                    <li>
                      From Itch
                      <ItchInstructions></ItchInstructions>
                    </li>
                  </ul>
                </template>
              </button>
          </div>
          <div class="btn">
            <a 
              class="download" 
              href="http://stream.liberverse.net:1090/" 
              target="_blank" 
              :style="{ backgroundImage: `url(${require('@/assets/pixel64.png')}), linear-gradient(180deg, rgba(255,147,0,1) 0%, rgba(255,0,183,1) 100%)` }">
              <span class="download-title">Stream Online</span>
            </a>
          </div>
        </div>

        <div>
          <div class="btn">
            <a 
              id="creationkit"
              class="download" 
              href="https://create.liberverse.net/" 
              target="_blank" 
              :style="{ backgroundImage: `url(${require('@/assets/creationkit-bg.png')})` }">
              <div :style="{ backgroundImage: `url(${require('@/assets/creationkit64.png')})` }">
                <span class="download-title">Creation Kit<br><small>Create for LIBERVERSE</small></span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ItchInstructions from '@/components/ItchInstructions.vue'

export default {
  components: { ItchInstructions },
  props: {
    msg: String
  },
  data () {
    return {
      windowsDownloadOptionsVisible: false,
      linuxDownloadOptionsVisible: false,
      macDownloadOptionsVisible: false,
    }
  }
}
</script>

<style>

#hero, #hero-overlay {
  box-sizing: border-box;
  min-height: 100vh;
}

#hero-overlay {
  background-color: rgba(50, 50, 50, .8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

#hero img {
  width: 50vw;
}

#hero {
  text-align: center;
  font-size: 20px;
  background-position: center center;
  background-attachment: fixed;
  background-size: 100vh cover;
}

#hero p {
  padding-left: 10%;
  padding-right: 10%;
  color: white;
}

@media screen and (max-width: 800px) {
  #hero img { width: 80vw; }
}

@media screen and (min-width: 1600px) {
  #hero img { width: 30vw; }
}

#hero .download {
  display: inline-block;
  box-sizing: border-box;
  background-position: 10px 10px, 0 0;
  background-repeat: no-repeat;
  color: white;
  text-decoration: none;
  border-radius: 15px;
  width: 330px;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.download {
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, .5));
}

#download-options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

div.btn {
  margin: 10px;
}

span.download-title {
  display: inline-block;
  margin: 30px;
  margin-left: 80px;
  margin-right: 20px;
}

button.download ul {
  margin-top: 0;
}

button.download li {
  text-align: left;
  font-size: 18px;
  color: white;
}

button.download li:not(:first-child) {
  text-align: left;
  margin-top: 20px;
}

button.download button {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

button.download button,
button.download a, 
button.download a:visited, 
button.download a:active, 
button.download a:link {
  font-size: 18px;
  color: white;
  text-decoration: underline;
}

button.download ol {
  margin-top: 10px;
}

button.download ol li {
  font-size: 16px;
  color: white;
  margin-top: 5px !important;
}

button.download ol button, 
button.download ol a, 
button.download ol a:visited, 
button.download ol a:active, 
button.download ol a:link {
  font-size: 16px;
  color: white;
  text-decoration: underline;
}

.download .requirements {
  font-size: 11px;
  color: #333 !important;
}

#creationkit {
  border-width: 15px;
  border-radius: 15px;
  background-position: 0 0, 0 0 !important;
  background-size: cover;
}

#creationkit span {
  font-family: 'Dancing Script', cursive;
  font-size: 32px;
  position: relative;
  top: 10px;
  margin: 5px;
  margin-top: 0;
  margin-left: 80px;
  margin-right: 20px;
  color: #ffeeb7;
}

#creationkit div {
  background-position: 30px center, 0 0;
  background-repeat: no-repeat;
  border-radius: 15px;
  cursor: pointer;
}

#creationkit small {
  font-family: 'Ubuntu', sans-serif;
  font-size: 12px;
  position: relative;
  bottom: 13px;
  color: white;
}

</style>