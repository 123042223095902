<template>
  <nav id="footer">
    <a href="mailto:hello@liberverse.net">hello@liberverse.net</a>
  </nav>
</template>

<script>
export default {
  props: {
    msg: String
  }
}
</script>

<style>
#footer {
  font-family: 'Ubuntu Condensed', sans-serif;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 50px;
  display: flex;
  justify-content: center;
  background-color: white;
}

#footer a, #footer a:visited {
  color: #333;
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

#footer a:hover, #footer a:active {
  color: #777;
}

</style>